import { Grid, Typography, IconButton } from "@material-ui/core"
import { FastRewind, ChevronLeft, FastForward, ChevronRight } from "@material-ui/icons"
import React, { useState, useEffect, cloneElement } from "react"

/**
 * @param {{
 * items: JSX.Element[]
 * itemsPerPage?: number
 * height?: number
 * }} props
 * @returns {JSX.Element | null}
 */
export const CarouselItems = (props) => {
  const { items } = props

  const [viewItems, setViewItems] = useState(/** @type {JSX.Element[]} */ ([]))

  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(0)

  const itemsPerPage = 10
  const height = props.height || 450

  useEffect(() => {
    setPages(Math.ceil(items.length / itemsPerPage) - 1)
    setPage(0)
  }, [items])

  useEffect(() => {
    const viewItems = items.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)

    setViewItems(viewItems)
  }, [items, page])

  return (
    <Grid id="container" container spacing={2} style={{ margin: 0, width: "100%" }}>
      <div
        style={{
          overflow: "hidden",
          padding: 20,
          whiteSpace: "nowrap",
          overflowX: "auto",
          flexWrap: "nowrap",
          display: "flex",
        }}
      >
        {!items.length ? <Typography>No items.</Typography> : null}
        {viewItems.map((item, index) => {
          return (
            <div key={index} style={{ width: 300, minWidth: 300, marginRight: 24, display: "inline-block", height }}>
              {cloneElement(item)}
            </div>
          )
        })}
      </div>
      <Grid item xs={12}>
        <div style={{ float: "left" }}>
          <IconButton color="primary" disabled={page === 0} onClick={() => setPage(0)}>
            <FastRewind fontSize="large" />
          </IconButton>
          <IconButton color="primary" disabled={page === 0} onClick={() => setPage(page - 1)}>
            <ChevronLeft fontSize="large" />
          </IconButton>
        </div>

        <div style={{ float: "right" }}>
          <IconButton color="primary" disabled={page === pages} onClick={() => setPage(page + 1)}>
            <ChevronRight fontSize="large" />
          </IconButton>
          <IconButton color="primary" disabled={page === pages} onClick={() => setPage(pages)}>
            <FastForward fontSize="large" />
          </IconButton>
        </div>
      </Grid>
    </Grid>
  )
}
