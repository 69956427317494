import React from "react"
import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import { ListBook as ReactListBook } from "../React/ListBook"

export default class ListBook extends ReactMaterialComponentBase {
  static get observedAttributes() {
    return ["target", "mode"]
  }

  constructor() {
    super()

    /**
     * @type {Array<import("../Types").Book>}
     */
    this.books = []

    this.jsxRootComponent = () => <ReactListBook mode={this.getAttribute("mode")} books={this.books} />

    this.target = this.getAttribute("target")

    this.getBooks()
  }

  async attributeChangedCallback(name, oldValue, newValue) {
    if (name !== "target") {
      return
    }

    if (!newValue) {
      return
    }

    this.target = newValue

    await this.getBooks()
  }

  async getBooks() {
    if (!this.target) {
      return
    }

    try {
      const token = await window.authorise()

      const getBooksResponse = await fetch(this.target, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })

      const books = /** @type {Array<import("../Types").Book>} */ (await getBooksResponse.json())

      this.books = books

      this.render()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }

  /**
   * @param {import("../Types").Book} book
   */
  async updateBook(book) {
    try {
      const token = await window.authorise()

      await fetch(`${this.target}/${book.id}`, {
        method: "PUT",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(book),
      })

      this.getBooks()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }
}

customElements.define("list-book", ListBook)
