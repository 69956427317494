import React, { useContext, useRef, useState } from "react"
import {
  Card,
  CardHeader,
  Dialog,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core"
import { ShadowContext } from "../../Shared/ShadowContext"
import { BookImage } from "./Images"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { useEffect } from "react"
import { CarouselItems } from "./CarouselItems"

/**
 * @param {{ book: import("../Types").Book; onClick: (book: import("../Types").Book) => void; selectedBook?: import("../Types").Book | null }} props
 * @returns {JSX.Element}
 */
const BookCard = (props) => {
  const { book, onClick, selectedBook } = props

  const [menuOpen, setMenuOpen] = useState(false)

  const { shadowRoot } = useContext(ShadowContext)

  const ref = useRef(null)

  const isSelected = selectedBook && selectedBook.name === book.name

  return (
    <>
      <Card onClick={() => onClick(book)}>
        <div style={{ backgroundColor: isSelected ? "#3f51b5" : undefined }}>
          <CardHeader
            title={
              <Typography style={{ whiteSpace: "normal", color: isSelected ? "white" : undefined }}>
                {book.name}
              </Typography>
            }
            disableTypography={true}
            action={
              <IconButton
                ref={ref}
                aria-label="settings"
                onClick={(event) => {
                  event.stopPropagation()
                  setMenuOpen(!menuOpen)
                }}
              >
                <MoreVertIcon style={{ color: isSelected ? "white" : undefined }} />
              </IconButton>
            }
          />
        </div>
        <Menu
          container={ref.current}
          anchorEl={ref.current}
          open={menuOpen}
          onClose={(event) => {
            event.stopPropagation && event.stopPropagation()
            setMenuOpen(false)
          }}
        >
          <MenuItem
            onClick={() => {
              shadowRoot.dispatchEvent(new CustomEvent("edit-book", { detail: book, composed: true, bubbles: true }))
            }}
          >
            Edit
          </MenuItem>
        </Menu>
        <BookImage clickDisabled={true} book={book} />
      </Card>
    </>
  )
}

const BookRecipesDialog = (props) => {
  const { onClose, open, shadowRoot, book } = props

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      container={() => shadowRoot.querySelector("#container")}
      onClose={onClose}
      open={open}
      disableEnforceFocus={true}
      PaperProps={{
        style: {
          width: "100%",
          overflowX: "hidden",
        },
      }}
    >
      {book ? <search-recipe-book target={`./mealplan/recipe/book/${book.name}`}></search-recipe-book> : null}
    </Dialog>
  )
}

const BookRecipesInline = (props) => {
  const { book } = props

  return (
    <>
      {book ? (
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={12}>
            <Typography variant="h4">{`Recipes for ${book.name}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <search-recipe-book
              style={{ width: "100%" }}
              target={`./mealplan/recipe/book/${book.name}`}
            ></search-recipe-book>
          </Grid>
        </Grid>
      ) : null}
    </>
  )
}

/**
 * @param {{ books: Array<import("../Types").Book>; setBooks: (books: Array<import("../Types").Book>) => void }} props
 * @returns
 */
const BookFilters = (props) => {
  const { books, setBooks } = props

  const [filterName, setFilterName] = useState("")

  useEffect(() => {
    if (!filterName) {
      setBooks(books)
      return
    }

    setBooks(
      books.filter((book) => {
        return book.name.toLocaleLowerCase().includes(filterName)
      })
    )
  }, [filterName])

  return (
    <FormGroup row={true}>
      <FormControl>
        <TextField
          value={filterName}
          onChange={(event) => setFilterName(event.target.value)}
          label="Filter Name"
          helperText="Filter books by name"
          variant="outlined"
        />
      </FormControl>
    </FormGroup>
  )
}

const BookListDisplay = (props) => {
  const { books, setBook, mode, selectedBook } = props

  const [selectedBooks, setSelectedBooks] = useState(books)

  useEffect(() => {
    setSelectedBooks(books)
  }, [books])

  return (
    <>
      <Grid item xs={12}>
        <BookFilters books={books} setBooks={setSelectedBooks} />
      </Grid>
      {mode === "inline" ? (
        <Grid item xs={12}>
          <CarouselItems
            items={selectedBooks.map((book, index) => {
              return <BookCard selectedBook={selectedBook} key={index} onClick={(book) => setBook(book)} book={book} />
            })}
            height={350}
          />
        </Grid>
      ) : (
        <>
          {selectedBooks.map((book, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <BookCard onClick={(book) => setBook(book)} book={book} />
              </Grid>
            )
          })}
        </>
      )}
    </>
  )
}

/**
 * @param {{ books: Array<import("../Types").Book>; mode: "inline" | undefined }} props
 * @returns {JSX.Element}
 */
export const ListBook = (props) => {
  const { books, mode } = props

  const [book, setBook] = useState(null)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(!!book)
  }, [book])

  const { shadowRoot } = useContext(ShadowContext)

  return (
    <>
      <Grid id="container" container spacing={2}>
        <BookListDisplay selectedBook={book} setBook={setBook} books={books} mode={mode} />
        {mode === "inline" ? (
          <BookRecipesInline book={book} />
        ) : (
          <BookRecipesDialog
            open={open}
            onClose={() => {
              setBook(null)
            }}
            book={book}
            shadowRoot={shadowRoot}
          />
        )}
      </Grid>
    </>
  )
}
